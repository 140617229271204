import { useFormik } from 'formik'
import React from 'react'
import ReactGA from 'react-ga4'
import * as Yup from 'yup'

import { EuiButton, EuiCheckbox, EuiFieldPassword, EuiFieldText, EuiForm, EuiFormRow, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'

import { ApiError, apiLogin } from 'api/adcritterApi'
import { useRedirectAfterLogin } from 'features/outside/login/utils/useRedirectAfterLogin'
import { useUpdateStateAfterLogin } from 'features/outside/login/utils/useUpdateAfterLogin'
import { useUpdatePixelAfterLogin } from 'features/outside/login/utils/useUpdatePixelAfterLogin'
import history from 'services/HistoryService'
import { deleteRememberedEmail, setRememberedEmail } from 'services/LocalStoreManagerService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import config from '../../../app/config'

interface FormValues {
  email: string
  password: string
  rememberMe: boolean
}

interface FormProps {
  rememberedEmail: string
  redirect: string | null
  emailInQuery: string | null
}

const LoginForm = ({ rememberedEmail, redirect, emailInQuery }: FormProps) => {
  const whiteLabel = useWhiteLabel()
  const updateStateAfterLogin = useUpdateStateAfterLogin()
  const updatePixelAndInsightsAfterLogin = useUpdatePixelAfterLogin()
  const redirectAfterLogin = useRedirectAfterLogin('/')

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required('Please enter your email address').lowercase().trim(),
    password: Yup.string().required('Please enter your password')
  })

  const formik = useFormik({
    initialValues: {
      email: emailInQuery ?? rememberedEmail ?? '',
      password: '',
      rememberMe: !!rememberedEmail
    },
    validationSchema: loginSchema,
    onSubmit: (values: FormValues) => {
      if (values.rememberMe) {
        setRememberedEmail(values.email)
      } else {
        deleteRememberedEmail()
      }

      apiLogin(values.email, values.password)
        .then(async authResponse => {
          if (authResponse.errors) {
            if (authResponse.errors[0].name) {
              authResponse.errors.forEach(function (e: ApiError) {
                formik.setFieldError(e.name, e.message)
              })
            } else {
              formik.setStatus(authResponse.errors[0].message)
            }
            formik.setSubmitting(false)
          } else {
            updateStateAfterLogin(authResponse.data)

            formik.setStatus(null)
            formik.setSubmitting(false)

            updatePixelAndInsightsAfterLogin(authResponse.data)
            if (config.gtag.ANALYTICSID) {
              ReactGA.event('login', { send_to: config.gtag.ANALYTICSID })
            }
            redirectAfterLogin(redirect, authResponse.data)
          }
        })
        .catch(() => {
          formik.setSubmitting(false)
        })
    }
  })

  return (
    <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.status && !!formik.errors} error={formik.status}>
      <EuiFormRow label='Email' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} error={formik.errors.email}>
        <EuiFieldText name='email' value={formik.values.email} onChange={formik.handleChange} icon='email' placeholder='username@domain.com' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} tabIndex={1} autoComplete='email' />
      </EuiFormRow>

      <EuiFormRow
        label='Password'
        labelAppend={
          <EuiText size={'xs'}>
            {!whiteLabel?.disableSignup() && (
              <EuiLink
                color='success'
                onClick={() => {
                  history.push('/forgot')
                }}
                tabIndex={4}>
                Forgot Password?
              </EuiLink>
            )}
          </EuiText>
        }
        fullWidth
        isInvalid={formik.touched.password && !!formik.errors.password}
        error={formik.errors.password}>
        <EuiFieldPassword name='password' fullWidth isInvalid={formik.touched.password && !!formik.errors.password} tabIndex={2} autoComplete='off' />
      </EuiFormRow>

      <EuiFormRow>
        <EuiCheckbox id='rememberMe' name='rememberMe' checked={formik.values.rememberMe} onChange={value => formik.setFieldValue('rememberMe', value, true)} label='Remember my email' />
      </EuiFormRow>
      <EuiSpacer />
      <EuiButton id='logIn' fill type='submit' isLoading={formik.isSubmitting} tabIndex={3}>
        Log In
      </EuiButton>
    </EuiForm>
  )
}

export default LoginForm
